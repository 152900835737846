<template>
    <div class="container">
        <h3>邮件发送情况</h3>
        <div class="coupon-generate">
            <div class="date">
                <p>发送日期:</p>
                <el-date-picker unlink-panels size="default" style="height: 32px" v-model="dateValue" type="daterange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts"
                    :default-value="[new Date(2024, 6, 1), new Date(2024, 7, 1)]" />
            </div>
            <div class="type">
                <p>邮件类型:</p>
                <el-select v-model="emailTypeValue" clearable placeholder="请选择邮件类型" style="width: 240px" @change="handleSelectChange">
                    <el-option v-for="item in emailOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <el-button type="primary" @click="winBackEmailSend()">筛选</el-button>
        </div>
        <div class="data-text">
            <div v-if="emailTypeValue == 'WinBack'">
                <h3>全部</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataOne ? receiveDataOne.send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataOne ? receiveDataOne.open : ''}}次</p>
                            <p>打开率：{{receiveDataOne ? receiveDataOne.open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataOne ? receiveDataOne.click : ''}}次</p>
                            <p>点击率：{{receiveDataOne ? receiveDataOne.click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataOne ? receiveDataOne.bounce : ''}}次</p>
                            <p>退回率：{{receiveDataOne ? receiveDataOne.bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataOne ? receiveDataOne.complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataOne ? receiveDataOne.complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
                <h3>第一封（订阅过期邮件）</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataTwo ? receiveDataTwo[0].send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataTwo ? receiveDataTwo[0].open : ''}}次</p>
                            <p>打开率：{{receiveDataTwo ? receiveDataTwo[0].open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataTwo ? receiveDataTwo[0].click : ''}}次</p>
                            <p>点击率：{{receiveDataTwo ? receiveDataTwo[0].click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataTwo ? receiveDataTwo[0].bounce : ''}}次</p>
                            <p>退回率：{{receiveDataTwo ? receiveDataTwo[0].bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataTwo ? receiveDataTwo[0].complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataTwo ? receiveDataTwo[0].complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
                <h3>第二封</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataTwo ? receiveDataTwo[1].send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataTwo ? receiveDataTwo[1].open : ''}}次</p>
                            <p>打开率：{{receiveDataTwo ? receiveDataTwo[1].open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataTwo ? receiveDataTwo[1].click : ''}}次</p>
                            <p>点击率：{{receiveDataTwo ? receiveDataTwo[1].click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataTwo ? receiveDataTwo[1].bounce : ''}}次</p>
                            <p>退回率：{{receiveDataTwo ? receiveDataTwo[1].bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataTwo ? receiveDataTwo[1].complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataTwo ? receiveDataTwo[1].complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
                <h3>第三封</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataTwo ? receiveDataTwo[2].send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataTwo ? receiveDataTwo[2].open : ''}}次</p>
                            <p>打开率：{{receiveDataTwo ? receiveDataTwo[2].open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataTwo ? receiveDataTwo[2].click : ''}}次</p>
                            <p>点击率：{{receiveDataTwo ? receiveDataTwo[2].click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataTwo ? receiveDataTwo[2].bounce : ''}}次</p>
                            <p>退回率：{{receiveDataTwo ? receiveDataTwo[2].bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataTwo ? receiveDataTwo[2].complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataTwo ? receiveDataTwo[2].complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                v-if="emailTypeValue == 'Confirm' || emailTypeValue == 'All' ||emailTypeValue == 'TrialExpired' || emailTypeValue == 'BlackFriday' || emailTypeValue == 'IranAgent' || emailTypeValue == 'GiftTime' || emailTypeValue == 'SubCanceled'">
                <div class="data-style">
                    <p>共送达：{{receiveDataOne ? receiveDataOne.send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataOne ? receiveDataOne.open : ''}}次</p>
                            <p>打开率：{{receiveDataOne ? receiveDataOne.open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataOne ? receiveDataOne.click : ''}}次</p>
                            <p>点击率：{{receiveDataOne ? receiveDataOne.click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataOne ? receiveDataOne.bounce : ''}}次</p>
                            <p>退回率：{{receiveDataOne ? receiveDataOne.bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataOne ? receiveDataOne.complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataOne ? receiveDataOne.complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="emailTypeValue == 'PaymentSuc'">
                <h3>总套餐</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataOne ? receiveDataOne.send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataOne ? receiveDataOne.open : ''}}次</p>
                            <p>打开率：{{receiveDataOne ? receiveDataOne.open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataOne ? receiveDataOne.click : ''}}次</p>
                            <p>点击率：{{receiveDataOne ? receiveDataOne.click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataOne ? receiveDataOne.bounce : ''}}次</p>
                            <p>退回率：{{receiveDataOne ? receiveDataOne.bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataOne ? receiveDataOne.complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataOne ? receiveDataOne.complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
                <h3>主套餐</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataTwo ? receiveDataTwo[0].send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataTwo ? receiveDataTwo[0].open : ''}}次</p>
                            <p>打开率：{{receiveDataTwo ? receiveDataTwo[0].open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataTwo ? receiveDataTwo[0].click : ''}}次</p>
                            <p>点击率：{{receiveDataTwo ? receiveDataTwo[0].click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataTwo ? receiveDataTwo[0].bounce : ''}}次</p>
                            <p>退回率：{{receiveDataTwo ? receiveDataTwo[0].bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataTwo ? receiveDataTwo[0].complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataTwo ? receiveDataTwo[0].complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
                <h3>拓展设备</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataTwo ? receiveDataTwo[1].send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataTwo ? receiveDataTwo[1].open : ''}}次</p>
                            <p>打开率：{{receiveDataTwo ? receiveDataTwo[1].open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataTwo ? receiveDataTwo[1].click : ''}}次</p>
                            <p>点击率：{{receiveDataTwo ? receiveDataTwo[1].click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataTwo ? receiveDataTwo[1].bounce : ''}}次</p>
                            <p>退回率：{{receiveDataTwo ? receiveDataTwo[1].bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataTwo ? receiveDataTwo[1].complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataTwo ? receiveDataTwo[1].complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="emailTypeValue == 'DelAccount'">
                <h3>删除账号通知邮件</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataTwo ? receiveDataTwo[0].send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataTwo ? receiveDataTwo[0].open : ''}}次</p>
                            <p>打开率：{{receiveDataTwo ? receiveDataTwo[0].open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataTwo ? receiveDataTwo[0].click : ''}}次</p>
                            <p>点击率：{{receiveDataTwo ? receiveDataTwo[0].click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataTwo ? receiveDataTwo[0].bounce : ''}}次</p>
                            <p>退回率：{{receiveDataTwo ? receiveDataTwo[0].bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataTwo ? receiveDataTwo[0].complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataTwo ? receiveDataTwo[0].complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
                <h3>删除账号重新激活邮件</h3>
                <div class="data-style">
                    <p>共送达：{{receiveDataTwo ? receiveDataTwo[1].send : ''}}封</p>
                    <ul class="data-ul-style">
                        <li>
                            <p>打开数：{{receiveDataTwo ? receiveDataTwo[1].open : ''}}次</p>
                            <p>打开率：{{receiveDataTwo ? receiveDataTwo[1].open_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>点击数：{{receiveDataTwo ? receiveDataTwo[1].click : ''}}次</p>
                            <p>点击率：{{receiveDataTwo ? receiveDataTwo[1].click_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>退回数：{{receiveDataTwo ? receiveDataTwo[1].bounce : ''}}次</p>
                            <p>退回率：{{receiveDataTwo ? receiveDataTwo[1].bounce_rate : ''}}%</p>
                        </li>
                        <li>
                            <p>投诉数：{{receiveDataTwo ? receiveDataTwo[1].complaint : ''}}次</p>
                            <p>投诉率：{{receiveDataTwo ? receiveDataTwo[1].complaint_rate : ''}}%</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {
        ref
    } from "vue";
    import {
        email_send_select
    } from "../services/requests.js";
    import {
        ElMessage,
        ElLoading
    } from "element-plus";

    const emailTypeValue = ref("WinBack");
    const end = new Date();
    const start = new Date();
    const payStart = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    payStart.setTime(payStart.getTime() - 3600 * 1000 * 24 * 7);
    const dateValue = ref([start, end]);
    const receiveDataOne = ref()
    const receiveDataTwo = ref()
    const emailOptions = [
        {
            label: "全部",
            value: "All",
        },
        {
            label: "赢回邮件",
            value: "WinBack",
        },
        {
            label: "黑五活动",
            value: "BlackFriday",
        },
        {
            label: "试用过期",
            value: "TrialExpired",
        },
        {
            label: "取消订阅",
            value: "SubCanceled",
        },
        {
            label: "删除账号",
            value: "DelAccount",
        },
        {
            label: "订阅成功",
            value: "PaymentSuc",
        },
        {
            label: "验证码",
            value: "Confirm",
        },
        {
            label: "伊朗代理人招募",
            value: "IranAgent",
        },
        {
            label: "奖励时长",
            value: "GiftTime",
        },
    ];

    const shortcuts = [{
            text: "过去一周",
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                return [start, end];
            },
        },
        {
            text: "过去一个月",
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                return [start, end];
            },
        },
        {
            text: "过去三个月",
            value: () => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                return [start, end];
            },
        },
        {
            text: "所有时间",
            value: () => {
                const start = new Date("2024-01-01");
                const end = new Date(); // 当前日期
                return [start, end];
            },
        },
    ];

    const handleSelectChange = () => {
        receiveDataOne.value = ''
        receiveDataTwo.value = ''
    }

    const winBackEmailSend = () => {
        const loading1 = ElLoading.service({
            lock: true,
            text: "Loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        var startTime = formatDate(dateValue.value[0]);
        var endTime = formatDate(dateValue.value[1]);
        email_send_select(startTime, endTime, emailTypeValue.value)
            .then((res) => {
                var data = res.data;
                var code = data.code;
                if (code == 200) {
                    loading1.close();
                    if (data.data) {
                        const keysToRound = ['open_rate', 'click_rate', 'bounce_rate', 'complaint_rate'];
                        receiveDataOne.value = Object.keys(data.data).reduce((acc, key) => {
                            if (keysToRound.includes(key)) { // 检查是否是指定需要处理的键
                                if (data.data[key] === 'NA') {
                                    acc[key] = 0; // 当值为'NA'时赋值为0
                                } else {
                                    let value = parseFloat(data.data[key]);
                                    if (!isNaN(value)) { // 确保值是有效的数字
                                        acc[key] = parseFloat((Math.ceil(value * 10000) / 100).toFixed(
                                            2));
                                    } else {
                                        acc[key] = data.data[key]; // 如果不是有效数字，则保留原值
                                    }
                                }
                            } else {
                                acc[key] = data.data[key]; // 其他属性直接复制
                            }
                            return acc;
                        }, {});
                    }
                    if (data.details) {
                        const keysToRound = ['open_rate', 'click_rate', 'bounce_rate', 'complaint_rate'];
                        receiveDataTwo.value = data.details.map(item => {
                            let newItem = {
                                ...item
                            };
                            Object.keys(newItem).forEach(key => {
                                if (keysToRound.includes(key)) { // 确保只对需要的键进行处理
                                    if (newItem[key] === 'NA') {
                                        newItem[key] = 0;
                                    } else {
                                        let value = parseFloat(newItem[key]);
                                        if (!isNaN(value)) { // 确保值是有效的数字
                                            newItem[key] = parseFloat((Math.ceil(value *
                                                10000) / 100).toFixed(2));
                                        } else {
                                            newItem[key] = data.data[key]; // 如果不是有效数字，则保留原值
                                        }
                                    }
                                }
                            });
                            return newItem;
                        });
                    }
                    ElMessage({
                        message: `邮件情况查询成功`,
                        type: "success",
                    });
                } else {
                    loading1.close();
                    ElMessage({
                        message: `查询失败, 请重试`,
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                ElMessage({
                    message: err,
                    type: "error",
                });
            });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // 获取年份、月份和日期
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以需要加1
        const day = ("0" + date.getDate()).slice(-2);

        // 拼接成 YYYY-MM-DD 格式
        return `${year}-${month}-${day}`;
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .data-style {
        display: flex;
        align-items: baseline;
    }

    .data-ul-style {
        display: flex;
        align-items: center;
        list-style-type: none;
        text-align: left;
    }

    .data-ul-style li {
        padding-right: 20px;
    }

    .chart-container p {
        font-size: 18px;
        font-weight: bold;
    }

    .pie-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .remark-text {
        text-align: left;
    }

    .remark-text ol li {
        line-height: 30px;
    }

    .cell-remark {
        display: inline-block;
        max-width: 200px;
        /* 根据需要调整宽度 */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

    .status-container {
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .status-container p {
        margin-right: 20px;
    }

    .container {
        width: 1400px;
        padding: 20px;
        margin: 0 auto;
    }

    .data-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
    }

    .data-text-two {
        flex-direction: column;
        font-weight: bold;
        border: 0px;
    }

    .coupon-generate,
    .issuance-record {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    .email,
    .type,
    .remark,
    .status,
    .date {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }

    .email p,
    .type p,
    .remark p,
    .status p,
    .date p {
        margin-right: 10px;
    }

    h3 {
        text-align: left;
        margin: 0px;
    }
</style>