<template>
    <div class="my_table">
        <table style="width: 100%; border: 1px; ;" cellspacing="0" border="1" cellpadding="0">

            <thead>
                <tr>
                    <th class="head_bg_short">日期</th>
                    <th class="head_bg_short">新增设备数</th>
                    <th :class="!show_details ? 'head_bg_short' : 'head_bg'">总收入</th>
                    <th class="head_bg_short" v-if="!show_details">LTV</th>
                    <th :class="!show_details ? 'head_bg_short' : 'head_bg'">总试用数</th>
                    <th :class="!show_details ? 'head_bg_short' : 'head_bg'">新增付费收入</th>
                    <th :class="!show_details ? 'head_bg_short' : 'head_bg'">续订收入 </th>
                    <th :class="!show_details ? 'head_bg_short' : 'head_bg'">当天试用转正收入</th>
                    <th :class="!show_details ? 'head_bg_short' : 'head_bg'">退订收入</th>
                    <th :class="!show_details ? 'head_bg_short' : 'head_bg_long'">归因试用转正收入</th>
                    <th class="head_bg_short" v-if="!show_details">归因试用付费率</th>
                    <th class="head_bg_short" v-if="!show_details">归因付费率</th>
                    <th class="head_bg_short" v-if="!show_details">转正率</th>
                    <th class="head_bg_short" v-if="!show_details">试用率</th>

                </tr>
            </thead>

            <tr :key="index" v-for="(plan_items, index) in data">
                <td>
                    <div>{{plan_items.date}}</div>
                </td>
                <!--订阅日期-->
                <td>
                    <div>{{plan_items.new_device >0 ? plan_items.new_device : ''}}</div>
                </td>
                <!--新增设备数-->
                <!--总收入-->
                <td>
                    <div v-if="show_details">
                        <div v-if="plan_items.date_total_money!=0">

                            <table style="width:100%;height: 200px;" class="collapse" v-if="show_details">
                                <tr>
                                    <th>收入类型</th>
                                    <th>金额</th>
                                </tr>
                                <tr>
                                    <td>新增收入</td>
                                    <td>{{plan_items.date_money >0 ? plan_items.date_money: ''}}</td>
                                </tr>
                                <tr>
                                    <td>续订收入</td>
                                    <td>{{plan_items.date_renew_money >0 ? plan_items.date_renew_money: ''}}</td>
                                </tr>
                                <tr>
                                    <td>当天转正收入</td>
                                    <td>{{plan_items.date_now_free_renew_money >0 ? plan_items.date_now_free_renew_money: ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>退款金额</td>
                                    <td style="color:red">
                                        <div v-if="plan_items.date_refund_money> 0">
                                            -{{plan_items.date_refund_money}}
                                        </div>
                                    </td>
                                </tr>

                            </table>
                            <div style="text-align:left;margin-left:10px">
                                <div style="margin-top:10px">
                                    <div style="height:30px">
                                        合计: {{plan_items.total_pay}}
                                    </div>
                                    <div style="height:30px">
                                        总收入: {{plan_items.date_total_money}}
                                    </div>
                                    <div style="height:30px">
                                        归因付费率: {{plan_items.pay_rate}}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{plan_items.date_total_money !=0 ? plan_items.date_total_money : ''}}
                    </div>
                </td>
                <!--LTV生命周期价值-->
                <td v-if="!show_details">
                    <div v-if="plan_items.ltv >0">{{plan_items.ltv}}</div>
                </td>
                <!--总试用数-->
                <td>
                    <div v-if="show_details">
                        <div v-if="plan_items.total_free>0">
                            <table style="width:100%;height: 200px;" class="collapse" v-if="show_details">
                                <tr>
                                    <th>套餐</th>
                                    <th>数量</th>
                                </tr>
                                <tr :key="index2" v-for="(detail_items, index2) in plan_items.details">
                                    <td>{{detail_items.plan}}</td>
                                    <td>{{detail_items.free >0 ?detail_items.free : '' }}</td>
                                </tr>

                            </table>
                            <div style="margin-left:10px;text-align:left">
                                <div style="margin-top:10px">
                                    <div style="height:30px">
                                        合计: {{plan_items.total_free}}
                                    </div>
                                    <div style="height:30px">
                                        试用率: {{plan_items.free_rate}}%
                                    </div>
                                    <div style="height:30px">
                                        转正率: {{plan_items.free_renew_rate}}%
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-else>
                        {{plan_items.total_free >0 ? plan_items.total_free: ''}}
                    </div>
                </td>

                <!--新增付费收入-->
                <td>
                    <div v-if="show_details">
                        <div v-if="plan_items.total_sub > 0">
                            <table style="width:100%;height: 200px;" class="collapse" v-if="show_details">
                                <tr>
                                    <th style="width:20px">套餐</th>
                                    <th style="width:20px">数量</th>
                                    <th style="width:20px">金额</th>
                                </tr>
                                <tr :key="index2" v-for="(detail_items, index2) in plan_items.details">
                                    <td>{{detail_items.plan}}</td>
                                    <td>
                                        <div v-if="detail_items.all_sub >0">
                                            {{detail_items.all_sub}}
                                            <span style="margin-left:2px">(</span>
                                            <span
                                                style="color:#e18a3b;margin-left:2px">{{detail_items.sub >0 ?detail_items.sub : ''}}</span>
                                            <span style="margin-left:2px">,</span>
                                            <span
                                                style="color:#0078d4;margin-left:2px">{{detail_items.promo_sub>0 ?detail_items.promo_sub : '' }}</span>
                                            <span style="margin-left:2px"> )</span>
                                        </div>
                                    </td>
                                    <td>{{detail_items.all_sub_money != 0 ? detail_items.all_sub_money : ''}}</td>
                                </tr>

                            </table>
                            <div style="text-align:left;margin-left:10px">
                                <div style="margin-top:10px">
                                    <div style="height:30px">
                                        合计: {{plan_items.total_sub}}
                                    </div>
                                    <div style="height:30px">
                                        新增收入: {{plan_items.date_money}}
                                    </div>
                                    <div style="height:30px">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{plan_items.date_money >0 ? plan_items.date_money : ''}}
                    </div>
                </td>

                <!--续订收入-->
                <td>
                    <div v-if="show_details">
                        <div v-if="plan_items.total_renew > 0">
                            <table style="width:100%;height: 200px;" class="collapse" v-if="show_details">
                                <tr>
                                    <th>套餐</th>
                                    <th>数量</th>
                                    <th>金额</th>
                                </tr>
                                <tr :key="index2" v-for="(detail_items, index2) in plan_items.details">
                                    <td>{{detail_items.plan}}</td>
                                    <td>{{detail_items.renew >0 ? detail_items.renew: ''}}</td>
                                    <td>{{detail_items.renew_money != 0 ? detail_items.renew_money : ''}}</td>
                                </tr>

                            </table>
                            <div style="text-align:left;margin-left:10px">
                                <div style="margin-top:10px">
                                    <div style="height:30px">
                                        合计: {{plan_items.total_renew>0 ?plan_items.total_renew : ''}}
                                    </div>
                                    <div style="height:30px">
                                        续订收入: {{plan_items.date_renew_money>0 ? plan_items.date_renew_money : ''}}
                                    </div>
                                    <div style="height:30px">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{plan_items.date_renew_money > 0 ? plan_items.date_renew_money : ''}}
                    </div>
                </td>

                <!--当天试用转正收入-->
                <td>
                    <div v-if="show_details">
                        <div v-if="plan_items.total_now_free_renew>0">
                            <table style="width:100%;height: 200px;" class="collapse" v-if="show_details">
                                <tr>
                                    <th>套餐</th>
                                    <th>数量</th>
                                    <th>金额</th>
                                </tr>
                                <tr :key="index2" v-for="(detail_items, index2) in plan_items.details">
                                    <td>{{detail_items.plan}}</td>
                                    <td>{{detail_items.now_free_renew >0 ? detail_items.now_free_renew : ''}}</td>
                                    <td>{{detail_items.now_free_renew_money != 0 ? detail_items.now_free_renew_money : ''}}</td>
                                </tr>
                            </table>
                            <div style="text-align:left;margin-left:10px">
                                <div style="margin-top:10px">
                                    <div style="height:30px">
                                        合计: {{plan_items.total_now_free_renew}}
                                    </div>
                                    <div style="height:30px">
                                        当天转正收入: {{plan_items.date_now_free_renew_money}}
                                    </div>
                                    <div style="height:30px">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="plan_items.date_now_free_renew_money > 0 ">{{plan_items.date_now_free_renew_money}}</div>
                    </div>
                </td>

                <!--退订收入-->
                <td>
                    <div v-if="show_details">
                        <div v-if="plan_items.total_refund>0">

                            <table style="width:100%;height: 200px;" class="collapse" v-if="show_details">
                                <tr>
                                    <th>套餐</th>
                                    <th>数量</th>
                                    <th>金额</th>
                                </tr>
                                <tr :key="index2" v-for="(detail_items, index2) in plan_items.details">
                                    <td>{{detail_items.plan}}</td>
                                    <td>
                                        <div v-if="detail_items.all_refund >0">
                                            {{detail_items.all_refund}}
                                            <span style="margin-left:2px">(</span>
                                            <span
                                                style="color:#e18a3b;margin-left:2px">{{detail_items.refund >0 ?detail_items.refund : ''}}</span>
                                            <span style="margin-left:2px">,</span>
                                            <span
                                                style="color:#0078d4;margin-left:2px">{{detail_items.promo_refund>0 ?detail_items.promo_refund : '' }}</span>
                                            <span style="margin-left:2px"> )</span>
                                        </div>
                                    </td>
                                    <td>{{detail_items.all_refund_money != 0 ? -detail_items.all_refund_money : ''}}</td>
                                </tr>

                            </table>
                            <div style="text-align:left;margin-left:10px">
                                <div style="margin-top:10px">
                                    <div style="height:30px">
                                        合计: {{plan_items.total_refund}}
                                    </div>
                                    <div style="height:30px">
                                        收入: -{{plan_items.date_refund_money}}
                                    </div>
                                    <div style="height:30px">
                                        退订率: {{plan_items.refund_rate}}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div v-if="plan_items.date_refund_money > 0 ">-{{plan_items.date_refund_money}}</div>
                    </div>
                </td>

                <!--归因试用转正收入-->
                <td>
                    <div v-if="show_details">
                        <div v-if="plan_items.total_free_renew>0">

                            <table style="width:100%;height: 200px;" class="collapse" v-if="show_details">
                                <tr>
                                    <th>套餐</th>
                                    <th>原转正</th>
                                    <th>原金额</th>
                                    <th>非原转正</th>
                                    <th>非金额</th>
                                </tr>
                                <tr :key="index2" v-for="(detail_items, index2) in plan_items.details">
                                    <td>{{detail_items.plan}}</td>
                                    <td>{{detail_items.free_renew >0 ? detail_items.free_renew : ''}}</td>
                                    <td>{{detail_items.free_renew_money >0 ? detail_items.free_renew_money : ''}}</td>
                                    <td>{{detail_items.non_free_renew >0 ? detail_items.non_free_renew : ""}}</td>
                                    <td>{{detail_items.non_free_renew_money >0 ? detail_items.non_free_renew_money : ""}}</td>
                                </tr>

                            </table>
                            <div style="text-align:left;margin-left:10px">
                                <div style="margin-top:10px">
                                    <div style="height:30px">
                                        合计: {{plan_items.total_free_renew }}
                                    </div>
                                    <div style="height:30px">
                                        收入: {{plan_items.date_free_renew_money}}
                                    </div>
                                    <div style="height:30px">
                                        归因试用付费率: {{plan_items.free_renew_pay_rate}}%
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-else>
                        {{plan_items.date_free_renew_money > 0 ? plan_items.date_free_renew_money : ""}}
                    </div>

                </td>

                <!--归因试用付费率-->
                <td v-if="!show_details">
                    <div v-if="plan_items.free_renew_pay_rate >0">{{plan_items.free_renew_pay_rate}}% </div>
                </td>
                <!--归因付费率-->
                <td v-if="!show_details">
                    <div v-if="plan_items.pay_rate >0">{{plan_items.pay_rate}}% </div>
                </td>
                <!--转正率-->
                <td v-if="!show_details">
                    <div v-if="plan_items.free_renew_rate >0">{{plan_items.free_renew_rate}}% </div>
                </td>
                <!--试用率-->
                <td v-if="!show_details">
                    <div v-if="plan_items.free_rate >0">{{plan_items.free_rate}}% </div>
                </td>
            </tr>
        </table>
    </div>

</template>

<script>
    import {
        toRefs,
        defineComponent,
    } from 'vue';

    export default defineComponent({
        props: {
            table_data: Array,
            renew_show: Boolean
        },
        name: "IncomeTable",
        setup(props) {
            const {
                table_data,
                renew_show
            } = toRefs(props)
            var data = table_data
            var show_details = renew_show
            return {
                data,
                show_details
            };
        }
    })
</script>
<style scoped>
    .my_table {
        zoom: 90%;
    }

    th {
        font-size: 16px;
        padding: 10px;
    }

    td {
        font-size: 16px;
        padding: 10px;
    }

    .head_bg_short {
        background-color: #f5f7fa;
        color: #909399;
        width: 100px;
    }

    .head_bg_long {
        background-color: #f5f7fa;
        color: #909399;
        width: 380px;

    }

    .head_bg {
        background-color: #f5f7fa;
        color: #909399;
        width: 260px;

    }

    thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    table {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0;
    }

    th,
    td {
        border: 1px solid #909399;
        text-align: center;
    }

    .collapse {
        border-collapse: collapse;
    }
</style>